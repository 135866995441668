export function assertNonNull<T>(
  value: T | null | undefined,
  reason?: string
): asserts value is NonNullable<T> {
  if (value === undefined || value === null) {
    throw new Error(reason || "value must be defined");
  }
}

export function asNonNull<T>(
  val: T | null | undefined,
  errorMessage?: string
): NonNullable<T> {
  assertNonNull(val, errorMessage);
  return val;
}
