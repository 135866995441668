export type Result<T> =
  | {
      status: "idle";
    }
  | {
      status: "loading";
    }
  | {
      status: "succeeded";
      value: T;
    }
  | {
      status: "errored";
      error: Error;
    };

export function isPending<T>(
  result: Result<T>
): result is { status: "idle" } | { status: "loading" } {
  return isIdle(result) || isLoading(result);
}

export function isIdle<T>(result: Result<T>): result is { status: "idle" } {
  return result.status === "idle";
}

export function isLoading<T>(
  result: Result<T>
): result is { status: "loading" } {
  return result.status === "loading";
}

export function isError<T>(
  result: Result<T>
): result is { status: "errored"; error: Error } {
  return result.status === "errored";
}

export function isSuccess<T>(
  result: Result<T>
): result is { status: "succeeded"; value: T } {
  return result.status === "succeeded";
}
