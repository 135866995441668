import React, { useEffect, useState } from "react";

import { BigNumber, ethers } from "ethers";

import { Web3Provider } from "@ethersproject/providers";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useWeb3React, Web3ReactProvider } from "@web3-react/core";
import { useLocation, BrowserRouter } from "react-router-dom";

import MFT_ABI from "./abi/MFT.json";
import { isError, isPending, Result } from "./result";
import { asNonNull } from "./utils";
import { SupportedChainId } from "./chains";
import { switchToNetwork } from "./switchToNetwork";
import { ClaimFlow } from "./components/ClaimFlow";
import {
  explorerAddressLink,
  mftAddress,
  openSeaCollectionLink,
} from "./constants/addresses";
import { AlreadyClaimedFlow } from "./components/AlreadyClaimedFlow";

const defaultChain =
  process.env.NODE_ENV === "production"
    ? SupportedChainId.POLYGON
    : SupportedChainId.LOCAL;

const injected = new InjectedConnector({
  supportedChainIds: [
    1,
    SupportedChainId.LOCAL,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ],
});

function truncateAddress(address: string) {
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
}

function getLibrary(provider: any): Web3Provider {
  return new Web3Provider(provider);
}

function Header({ disconnect }: { disconnect: () => Promise<void> }) {
  const { account, chainId } = useWeb3React();

  return (
    <div className="flex flex-row justify-between w-screen mt-3 space-x-3 px-3">
      <a className="logo w-10 h-10" href="https://markhudnall.com">
        <img
          alt="landakram-logo"
          src={process.env.PUBLIC_URL + "/landakram-logo.svg"}
        />
      </a>
      <div className="flex flex-col sm:flex-row space-y-3 sm:space-x-3 sm:space-y-0">
        <a
          target="_blank"
          rel="noreferrer"
          href={openSeaCollectionLink({
            collectionAddress: mftAddress(SupportedChainId.POLYGON_MUMBAI),
            chainId: SupportedChainId.POLYGON_MUMBAI,
          })}
          className="self-center bg-indigo-500 hover:bg-indigo-600 text-white py-2 px-4 rounded flex flex-row space-x-2"
        >
          <div>OpenSea collection</div>
          <div>↗️</div>
        </a>
        {account && chainId && (
          <div className="flex flex-col sm:flex-row space-y-3 sm:space-x-3 sm:space-y-0">
            <div className="py-2 px-2 rounded-lg bg-gray-900 text-white ml-auto">
              <a
                target="_blank"
                rel="noreferrer"
                className="hover:underline"
                href={explorerAddressLink(chainId, account)}
              >
                {truncateAddress(account)}
              </a>
            </div>
            <button
              onClick={disconnect}
              className="bg-indigo-500 hover:bg-indigo-600 text-white px-4 py-2 rounded"
            >
              Disconnect wallet
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

function Component() {
  const { activate, deactivate, active, account, library, chainId, error } =
    useWeb3React<Web3Provider>();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const mintPhrase = urlParams.get("p");
  const secret = urlParams.get("s");
  const [tokenId, setTokenId] = useState<Result<BigNumber>>({ status: "idle" });
  const [owner, setOwner] = useState<Result<string>>({ status: "idle" });
  // const [debugMessages, setDebugMessages] = useState<any[]>([]);

  async function connect() {
    try {
      await activate(injected, undefined, true);
    } catch (e: any) {
      console.log(e);
    }
  }

  async function disconnect() {
    try {
      setTokenId({ status: "idle" });
      setOwner({ status: "idle" });
      deactivate();
    } catch (e) {
      console.log(e);
    }
  }

  async function fetch() {
    if (library && chainId && account) {
      const contract = new ethers.Contract(
        mftAddress(chainId),
        MFT_ABI,
        library
      );

      if (mintPhrase) {
        const hashedMintPhrase = ethers.utils.solidityKeccak256(
          ["string"],
          [mintPhrase]
        );
        setTokenId({ status: "loading" });
        let tokenId: BigNumber;
        try {
          tokenId = await contract.mintPhrases(hashedMintPhrase);
          setTokenId({ status: "succeeded", value: tokenId });
        } catch (e: any) {
          setTokenId({
            status: "errored",
            error: e,
          });

          return;
        }

        let owner: string;
        try {
          setOwner({ status: "loading" });
          owner = await contract.ownerOf(tokenId.toString());
          setOwner({ status: "succeeded", value: owner });
        } catch (e: any) {
          if (e?.data?.message?.includes("owner query for nonexistent token")) {
            setOwner({
              status: "succeeded",
              value: ethers.constants.AddressZero,
            });
          } else {
            setOwner({
              status: "errored",
              error: e,
            });
          }
        }
      }
    }
  }

  useEffect(
    () => {
      if (library && chainId && chainId !== defaultChain) {
        switchToNetwork({ library, chainId: defaultChain })
          .catch((e) => {})
          .then(() => {
            disconnect();
          });
      } else if (chainId) {
        fetch();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [library, chainId, account, mintPhrase, secret]
  );

  console.log("render", { account, active, error, tokenId, owner });

  return (
    <>
      <div className="flex flex-col min-h-full items-center justify-center text-white bg-gradient-to-br from-blue-900 to-gray-900 p-3">
        <Header disconnect={disconnect} />
        <div className="flex flex-col w-screen sm:w-auto grow min-h-full items-center text-white p-3 mt-4 sm:mt-12 space-y-6">
          <div className="flex flex-col items-center space-y-2">
            <p className="text-2xl">🍄 🍄 🍄</p>
            <p className="text-2xl font-bold">MFTs</p>
            <p className="text-xl">Mark's (non-)Fungible Tokens</p>
          </div>
          <div className="flex max-w-xl w-full flex-col items-center">
            {error ? (
              error.message
            ) : active ? (
              <div className="flex flex-col space-y-3 w-full items-center">
                {isPending(tokenId) || isPending(owner) ? (
                  <button
                    disabled
                    className="disabled:bg-indigo-400 sm:w-40 w-full bg-indigo-500 hover:bg-indigo-600 text-white py-2 px-4 rounded"
                  >
                    Loading...
                  </button>
                ) : isError(tokenId) ? (
                  `Error: ${tokenId.error.message}`
                ) : isError(owner) ? (
                  `Error: ${owner.error.message}`
                ) : owner.value === account ? (
                  <AlreadyClaimedFlow
                    tokenId={tokenId}
                    chainId={asNonNull(chainId)}
                  />
                ) : owner.value === ethers.constants.AddressZero ? (
                  <ClaimFlow
                    tokenId={tokenId}
                    owner={owner}
                    mintPhrase={mintPhrase}
                    secret={secret}
                    onClaim={() => fetch()}
                  />
                ) : (
                  "Someone has already claimed this NFT. Better check with me to figure out what happened!"
                )}
              </div>
            ) : !secret || !mintPhrase ? (
              <div>
                Welcome to my NFT page! You should have received a special link
                to claim an NFT I made, just for you! I call them MFTs. If you
                lost your link, let me know and I can resend it.
              </div>
            ) : (
              <>
                <div className="space-y-2 mb-3">
                  <div>
                    Welcome to my NFT page! You received a special link to claim
                    an NFT I made, just for you! I call them MFTs.
                  </div>
                  <div>
                    To claim your NFT, you'll need a self-custody crypto wallet.
                    Have a look at{" "}
                    <a
                      className="text-blue-200 underline hover:text-blue-400"
                      href="https://learncrypto.com/blog/keep-learning/what-is-web3-wallet-why-you-might-need-one"
                    >
                      this guide
                    </a>{" "}
                    to get set up with MetaMask, a popular crypto wallet for
                    desktop and mobile.
                  </div>
                  <div>
                    I used the{" "}
                    <a
                      className="text-blue-200 underline hover:text-blue-400"
                      href="https://polygon.technology/"
                    >
                      Polygon
                    </a>{" "}
                    blockchain to create these NFTs. That means you'll need to
                    fund your wallet with the MATIC token.{" "}
                    <a
                      className="text-blue-200 underline hover:text-blue-400"
                      href="https://www.youtube.com/watch?v=LXzkVkPbvUs&t=0s"
                    >
                      This video
                    </a>{" "}
                    is a good starting point. If this is too confusing, ask me
                    and I can send you some MATIC!
                  </div>
                </div>
                <button
                  onClick={connect}
                  className="sm:w-40 w-full w-screen bg-indigo-500 hover:bg-indigo-600 text-white py-2 px-4 rounded"
                >
                  Connect wallet
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Component />
      </Web3ReactProvider>
    </BrowserRouter>
  );
}

export default App;
