export enum SupportedChainId {
  LOCAL = 31337,
  POLYGON = 137,
  POLYGON_MUMBAI = 80001,
}

interface AddNetworkInfo {
  readonly rpcUrl: string;
  readonly nativeCurrency: {
    name: string; // e.g. 'Goerli ETH',
    symbol: string; // e.g. 'gorETH',
    decimals: number; // e.g. 18,
  };
}

interface BaseChainInfo {
  readonly docs: string;
  readonly bridge?: string;
  readonly explorer: string;
  readonly infoLink: string;
  readonly label: string;
  readonly helpCenterUrl?: string;
  readonly addNetworkInfo: AddNetworkInfo;
}

export interface L1ChainInfo extends BaseChainInfo {}

export type ChainInfoMap = {
  readonly [chainId: number]: L1ChainInfo;
};

export const CHAIN_INFO: ChainInfoMap = {
  [SupportedChainId.POLYGON]: {
    bridge: "https://wallet.polygon.technology/bridge",
    docs: "https://polygon.io/",
    explorer: "https://polygonscan.com/",
    infoLink: "https://info.uniswap.org/#/polygon",
    label: "Polygon",
    addNetworkInfo: {
      rpcUrl: "https://polygon-rpc.com/",
      nativeCurrency: { name: "Polygon Matic", symbol: "MATIC", decimals: 18 },
    },
  },
  [SupportedChainId.POLYGON_MUMBAI]: {
    bridge: "https://wallet.polygon.technology/bridge",
    docs: "https://polygon.io/",
    explorer: "https://mumbai.polygonscan.com/",
    infoLink: "https://info.uniswap.org/#/polygon",
    label: "Polygon Mumbai",
    addNetworkInfo: {
      nativeCurrency: {
        name: "Polygon Mumbai Matic",
        symbol: "mMATIC",
        decimals: 18,
      },
      rpcUrl: "https://rpc-mumbai.maticvigil.com/",
    },
  },
};
