import { BigNumber } from "ethers";
import { mftAddress, openSeaTokenLink } from "../constants/addresses";
import { asNonNull } from "../utils";

export function AlreadyClaimedFlow({
  tokenId,
  chainId,
}: {
  tokenId: { status: "succeeded"; value: BigNumber };
  chainId: number;
}) {
  return (
    <div className="flex flex-col space-y-3">
      <div>You claimed this NFT!</div>
      <a
        target="_blank"
        rel="noreferrer"
        href={openSeaTokenLink({
          collectionAddress: mftAddress(asNonNull(chainId)),
          tokenId: tokenId.value.toString(),
          chainId: asNonNull(chainId),
        })}
        className="self-center bg-indigo-500 hover:bg-indigo-600 text-white py-2 px-4 rounded flex flex-row space-x-2"
      >
        <div>View on OpenSea</div>
        <div>↗️</div>
      </a>
    </div>
  );
}
