import { Web3Provider, TransactionResponse } from "@ethersproject/providers";
import { BigNumber, ethers } from "ethers";
import { useEffect, useState } from "react";
import { mftAddress } from "../constants/addresses";
import { isError, isIdle, isLoading, isSuccess, Result } from "../result";

import MFT_ABI from "../abi/MFT.json";
import { useWeb3React } from "@web3-react/core";
import { assertNonNull } from "../utils";

function ClaimNote() {
  const [showDetails, setShowDetails] = useState<boolean>(false);

  return (
    <div className="space-y-2 sm:w-96 flex flex-col items-center">
      <p className="text-gray-200 w-full">
        Note: you will be asked to submit two transactions.{" "}
        <button
          className="text-blue-200 underline hover:text-blue-400"
          onClick={(e) => {
            e.preventDefault();
            setShowDetails(!showDetails);
          }}
        >
          {showDetails ? "Hide." : "Learn why."}
        </button>
      </p>
      {showDetails && (
        <div className="space-y-2 text-gray-200 w-full">
          <p>
            You claim your NFT by presenting a special, unique passphrase. This
            passphrase is part of the link I gave to you.
          </p>
          <p>
            Since all transactions on the blockchain are public, if you just
            submitted your unique passphrase, someone could observe your
            transaction before it gets finalized and submit a transaction in
            front of you to claim your NFT before you do 😭 (this is called{" "}
            <a
              target="_blank"
              rel="noreferrer"
              className="text-blue-200 underline hover:text-blue-400"
              href="https://en.wikipedia.org/wiki/Front_running"
            >
              front-running
            </a>
            ).
          </p>
          <p>
            We use a{" "}
            <a
              target="_blank"
              rel="noreferrer"
              className="text-blue-200 underline hover:text-blue-400"
              href="https://en.wikipedia.org/wiki/Commitment_scheme"
            >
              commitment scheme
            </a>{" "}
            to solve this. The first transaction commits you to your NFT, while
            the second transaction reveals your commitment and lets you claim
            it, preventing anyone from jumping in front of you!
          </p>
        </div>
      )}
    </div>
  );
}

export function ClaimFlow({
  tokenId,
  owner,
  mintPhrase,
  secret,
  onClaim,
}: {
  tokenId: Result<BigNumber>;
  owner: Result<string>;
  mintPhrase: string | null;
  secret: string | null;
  onClaim: () => void;
}) {
  const { account, library, chainId } = useWeb3React<Web3Provider>();
  const [commitment, setCommitment] = useState<string>();
  const [commitTx, setCommitTx] = useState<Result<TransactionResponse>>({
    status: "idle",
  });
  const [mintTx, setMintTx] = useState<Result<TransactionResponse>>({
    status: "idle",
  });

  async function claim() {
    assertNonNull(library);
    assertNonNull(account);
    assertNonNull(mintPhrase);
    assertNonNull(secret);
    assertNonNull(chainId);
    assertNonNull(commitment);

    setCommitTx({ status: "loading" });
    const contract = new ethers.Contract(
      mftAddress(chainId),
      MFT_ABI,
      library
    ).connect(library.getSigner());
    console.log("claim", "commit tx");
    try {
      const commitTx = await contract.commit(commitment);
      await commitTx.wait();
      setCommitTx(commitTx);
    } catch (e: any) {
      console.log(e);
      if (
        e.message.includes("User denied transaction signature") ||
        e.message.includes("User rejected")
      ) {
        setCommitTx({ status: "idle" });
        return;
      } else if (e.message.includes("cannot overwrite active commitment")) {
        // Just move on to the next step
        setCommitTx({ status: "succeeded", value: {} as any });
      } else {
        setCommitTx({ status: "errored", error: e });
        return;
      }
    }

    console.log("claim", "mint tx");
    setMintTx({ status: "loading" });
    try {
      const claimTx = await contract.mint(secret, mintPhrase);
      await claimTx.wait();
      setMintTx(claimTx);
      onClaim();
    } catch (e: any) {
      console.log(e);
      if (
        e.message.includes("User denied transaction signature") ||
        e.message.includes("User rejected")
      ) {
        setCommitTx({ status: "idle" });
        setMintTx({ status: "idle" });
      } else {
        setMintTx({ status: "errored", error: e });
      }
    }
  }

  useEffect(() => {
    console.log("commitment", account, secret, mintPhrase);
    if (account && secret && mintPhrase) {
      const commitment = ethers.utils.solidityKeccak256(
        ["address", "string", "string"],
        [account, secret, mintPhrase]
      );
      setCommitment(commitment);
      console.log("commitment", commitment);
    }
  }, [setCommitment, secret, account, mintPhrase]);

  const err = (isError(commitTx) && commitTx) || (isError(mintTx) && mintTx);

  return (
    <>
      <p className="font-bold">🎉 You have an NFT to claim! </p>
      {isSuccess(tokenId) &&
        isSuccess(owner) &&
        owner.value === ethers.constants.AddressZero &&
        (isIdle(commitTx) || err ? (
          <>
            {err && (
              <div className="bg-red-400 w-full p-2 rounded">
                Error: {err.error.message}
              </div>
            )}
            <button
              onClick={claim}
              className="sm:w-32 w-full self-center bg-indigo-500 hover:bg-indigo-600 text-white py-2 px-4 rounded"
            >
              Claim NFT
            </button>

            <ClaimNote />
          </>
        ) : isLoading(mintTx) ? (
          <>
            <button
              disabled
              className="sm:w-32 w-full self-center bg-indigo-500 disabled:bg-indigo-400 hover:bg-indigo-600 text-white py-2 px-4 rounded"
            >
              Minting...
            </button>
            <ClaimNote />
          </>
        ) : isLoading(commitTx) ? (
          <>
            <button
              disabled
              className="sm:w-32 w-full self-center bg-indigo-500 disabled:bg-indigo-400 hover:bg-indigo-600 text-white py-2 px-4 rounded"
            >
              Claiming...
            </button>
            <ClaimNote />
          </>
        ) : (
          <></>
        ))}
    </>
  );
}
