import { SupportedChainId } from "../chains";

export function mftAddress(chainId: number): string {
  if (chainId === SupportedChainId.POLYGON_MUMBAI) {
    return "0x78512154BFBF8781efBb2e931a8D89B208a9344E";
  } else if (chainId === SupportedChainId.LOCAL) {
    return "0x5FbDB2315678afecb367f032d93F642f64180aa3";
  } else if (chainId === SupportedChainId.POLYGON) {
    return "0x9CA95e8a19da30F9BBE39bFf8cC18dBe24398469";
  } else if (chainId === 1) {
    return "";
  }

  throw new Error("Unsupported network");
}

export function explorerAddressLink(chainId: number, address: string) {
  if (chainId === SupportedChainId.POLYGON_MUMBAI) {
    return "<todo>";
  } else if (chainId === SupportedChainId.LOCAL) {
    return `https://local.polygonscan.com/address/${address}`;
  } else if (chainId === SupportedChainId.POLYGON) {
    return `https://polygonscan.com/address/${address}`;
  } else if (chainId === 1) {
    return "";
  }

  throw new Error("Unsupported network");
}

export function openSeaTokenLink({
  collectionAddress,
  tokenId,
  chainId,
}: {
  collectionAddress: string;
  tokenId: string;
  chainId: number;
}): string {
  if (chainId === SupportedChainId.POLYGON_MUMBAI) {
    return `https://testnets.opensea.io/assets/mumbai/${collectionAddress}/${tokenId}`;
  } else if (chainId === SupportedChainId.LOCAL) {
    return `https://local.opensea.io/assets/${collectionAddress}/${tokenId}`;
  } else if (chainId === SupportedChainId.POLYGON) {
    return `https://opensea.io/assets/matic/${collectionAddress}/${tokenId}`;
  } else if (chainId === 1) {
    return "";
  }

  throw new Error("Unsupported network");
}

export function openSeaCollectionLink({
  collectionAddress,
  chainId,
}: {
  collectionAddress: string;
  chainId: number;
}): string {
  if (chainId === SupportedChainId.POLYGON_MUMBAI) {
    return `https://testnets.opensea.io/collection/marks-non-fungible-tokens`;
  } else if (chainId === SupportedChainId.LOCAL) {
    return `https://local.opensea.io/assets/${collectionAddress}/`;
  } else if (chainId === SupportedChainId.POLYGON) {
    return `https://opensea.io/collection/marks-non-fungible-tokens`;
  } else if (chainId === 1) {
    return "";
  }

  throw new Error("Unsupported network");
}
